import { NgModule } from "@angular/core";

// Components
import { PortfolioComponent } from "src/app/modules/shared/components/portfolio/portfolio.component";
import { HeaderComponent } from "src/app/modules/shared/components/header/header.component";

@NgModule({
  declarations: [HeaderComponent, PortfolioComponent],
  exports: [HeaderComponent, PortfolioComponent],
})
export class SharedModule {}
