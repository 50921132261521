import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

// Components
import { AppComponent } from "src/app/app.component";

// Environments
import { environment } from "src/environments/environment";

// Modules
import { SharedModule } from "src/app/modules/shared/shared.module";
import { AppRoutingModule } from "src/app/app-routing.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
